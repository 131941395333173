import styled from "styled-components"

export const HeroGroup = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeroTitle = styled.h1`
  color: transparent;
  font-size: 9vmin;
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  -webkit-text-stroke: 1px ${({ theme }) => theme.text};
  @media (max-width: 375px) {
    font-size: 12vmin;
  }
`
export const HeroSubtitle = styled.h2`
  text-align: center;
  line-height: 1;
  letter-spacing: 2px;
  font-size: 7vmin;
  margin-top: 0.2rem;
  margin-bottom: 1.5rem;
`
export const HeroInfo = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: HeroAnimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.82, 0.2, 1);
`
