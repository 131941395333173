import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import React from "react"
import { Button } from "../Buttons"
import { HeroGroup, HeroInfo, HeroSubtitle, HeroTitle } from "./styled"

const Stars = loadable(() => pMinDelay(import("./Stars"), 900))

export const HeroHomePage = ({ title, subtitle }) => {
  return (
    <HeroGroup>
      <Stars />
      <HeroInfo>
        <HeroTitle>{title}</HeroTitle>
        <HeroSubtitle>{subtitle}</HeroSubtitle>
        <Button
          text="Quiero ver los proyectos >>"
          url="/projects"
          large="true"
        />
      </HeroInfo>
    </HeroGroup>
  )
}
