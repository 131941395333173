import React from "react"
import { HeroHomePage } from "../components/Hero"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout hero>
    <Seo title="Home" />
    <HeroHomePage title="Hola Soy Alejandro" subtitle="Frontend Developer" />
  </Layout>
)

export default IndexPage
